/* eslint-disable */
import { ChakraProvider } from '@chakra-ui/react';
import { CSpinner, useColorModes } from '@coreui/react-pro';
import { APIProvider } from '@vis.gl/react-google-maps';
import { AnimatePresence } from 'framer-motion';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import ProtectedRoute from './ProtectedRoute';
import { persistor } from "./store/store";

import './scss/examples.scss';
import './scss/style.scss';

const Page500 = React.lazy(() => import('./Common/Page500'))
const Page404 = React.lazy(() => import('./Common/Page404'))
const RedirectPage = React.lazy(() => import('./Common/RedirectPage'))
const Login = React.lazy(() => import('./Components/Authentication/Login/Login'))
const SignUp = React.lazy(() => import('./Components/Authentication/SignUp/Signup'))
const ConfirmReg = React.lazy(() => import('./Components/Authentication/ConfirmReg/ConfirmReg'))
const VerifyEmail = React.lazy(() => import('./Components/Authentication/VerifyEmail/VerifyEmail'))
const ResetPassword = React.lazy(() => import('./Components/Authentication/ResetPassword/ResetPassword'))
const ConfirmPassword = React.lazy(() => import('./Components/Authentication/ConfirmPassword/ConfirmPassword'))
const OnBoarding = React.lazy(() => import('./Components/OnBording/OnBoarding'))
const Dashboard = React.lazy(() => import('./Components/Dashboard/Dashboard'))
const InviteCheck = React.lazy(() => import('./Components/Dashboard/Components/Settings/InviteCheck'))

function App() {

  const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-pro-react-admin-template-theme-modern',
  )
  const { theme: themeVal } = useSelector((state) => state.Theme)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }


    setColorMode(themeVal)
  }, [])


  return (
    <div className="App">
      <PersistGate loading={null} persistor={persistor}>
        <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
          <ChakraProvider>
            <AnimatePresence
              initial={false}
              onExitComplete={() => {
                if (typeof window !== 'undefined') {
                  window.scrollTo(0, 0);
                }
              }}
            >

              <Router>
                <Suspense
                  fallback={
                    <div className="pt-3 d-flex justify-content-center">
                      <CSpinner color="primary" variant="grow" />
                    </div>
                  }
                >
                  <Routes>

                    <Route exact path="/" name="RedirectPage" element={<RedirectPage />} />
                    <Route exact path="/login" name="Login Page" element={<Login />} />
                    <Route exact path="/register" name="Register Page" element={<SignUp />} />
                    <Route exact path="/confirm" name="Confirm" element={<ConfirmReg />} />
                    <Route exact path="/account/verify-email/:code" name="Confirm" element={<VerifyEmail />} />
                    <Route exact path="/reset" name="Reset" element={<ResetPassword />} />
                    <Route exact path="/accounts/invite/:code" name="Confirm" element={<InviteCheck />} />
                    <Route exact path="/account/password/reset/key/:code" name="Confirm" element={<ConfirmPassword />} />

                    {/* <Route path="/dashboard" element={<ProtectedRoute Component={Dashboard} />} /> */}
                    <Route path="/onBoarding" element={<ProtectedRoute Component={OnBoarding} />} />


                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />


                    {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
                    <Route path="*" element={<Dashboard />} />

                  </Routes>
                </Suspense>
              </Router>
            </AnimatePresence>
          </ChakraProvider>
        </APIProvider>
      </PersistGate>
    </div>
  );
}

export default App;
